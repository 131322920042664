import {BaseRequest} from "../../core/BaseRequest";
import {VpnServer} from "../server/VpnServer";
import {VpnAccount} from "./VpnAccount";


export class VpnAccountSaveRequest extends BaseRequest {

    public authentication: string | undefined;

    public data: VpnAccount | undefined;

    public servers: VpnServer[] | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, authentication: string | undefined, data: VpnAccount | undefined, servers: VpnServer[] | undefined) {

        super(timestamp, token);
        this.authentication = authentication;
        this.data = data;
        this.servers = servers;

    }

}
