import React, {useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {Status} from "../../../../enums/core/Status";
import {TableFilterType} from "../../../../enums/core/table/TableFilterType";
import {UserType} from "../../../../enums/user/UserType";
import {Core} from "../../../../libraries/Core";
import {Rest} from "../../../../libraries/Rest";
import {DataRequest} from "../../../../models/core/DataRequest";
import {Table} from "../../../../models/core/table/Table";
import {TableFilter} from "../../../../models/core/table/TableFilter";
import {TableRequest} from "../../../../models/core/table/TableRequest";
import {TableSort} from "../../../../models/core/table/TableSort";
import {NawalaGroup} from "../../../../models/nawala/group/NawalaGroup";
import {NawalaUrl} from "../../../../models/nawala/url/NawalaUrl";
import {NawalaUrlOption} from "../../../../models/nawala/url/NawalaUrlOption";
import TableFilterComponent from "../../TableFilterComponent";
import TablePaginationComponent from "../../TablePaginationComponent";
import NawalaUrlDataComponent from "./NawalaUrlDataComponent";


export default function NawalaUrlComponent() {

    // @ts-ignore
    const {account, canvasClose, canvasOpen} = useOutletContext();

    const [filters, changeFilters] = useState<TableFilter[]>([
        new TableFilter('group._id', 'Group', [], false, TableFilterType.ObjectId, ''),
        new TableFilter('url', 'URL', [], false, TableFilterType.String, ''),
        new TableFilter('status', 'Status', [], false, TableFilterType.Enum, '')
    ]);

    if(account.type !== undefined && account.type !== UserType.Owner) {

        let index: number = filters.findIndex(filter => filter.name === 'Group');

        if(index > -1) {

            filters.splice(index, 1);

        }

    }

    const [table, changeTable] = useState<Table>(
        Core.initializeTable([new TableSort('url', 1)])
    );

    const [nawalaUrl, changeNawalaUrl] = useState<NawalaUrl>(Core.initializeNawalaUrl());
    const [option, changeOption] = useState<NawalaUrlOption>(new NawalaUrlOption(
        []
    ));

    const canvas = (event: any, id: string | undefined = undefined) => {

        initializeData(id);
        canvasOpen();

        event.preventDefault();

    }

    const initializeData = (id: string | undefined = undefined) => {

        fetch(process.env.REACT_APP_API_URL + '/nawala/url/data/', Rest.initializeRequest(
            new DataRequest(undefined, undefined, Core.initializeAuthentication(), id),
            '/nawala/url/data/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                if(data.data !== null) {

                    changeNawalaUrl(data.data);

                } else {

                    changeNawalaUrl(Core.initializeNawalaUrl());

                }

                changeOption(new NawalaUrlOption(
                    data.groups
                ));

            }

        }).catch(error => {

            toast.error(error);

        });

    }

    const initializeTable = () => {

        fetch(process.env.REACT_APP_API_URL + '/nawala/url/table/', Rest.initializeRequest(
            new TableRequest(undefined, undefined, Core.initializeAuthentication(), filters, table.pagination!.limit!.value, table.offset, table.sorts),
            '/nawala/url/table/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                if(data.data !== undefined) {

                    changeTable(Core.renderNewTable(table, data.data, data.total));

                }

                filters[0].options = [];

                data.groups.forEach((group: NawalaGroup) => {

                    filters[0].options!.push({
                        'text': group.name,
                        'value': group.id
                    });

                })

                changeFilters(Core.renderFilter(filters));

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

    }

    const remove = (event: any, id: string, name: string) => {

        Swal.fire(Core.initializeSwalDelete('Are you sure want to delete?', name)).then((result) => {

            if(result.isConfirmed) {

                fetch(process.env.REACT_APP_API_URL + '/nawala/url/delete/', Rest.initializeRequest(
                    new DataRequest(undefined, undefined, Core.initializeAuthentication(), id),
                    '/nawala/url/delete/'
                )).then(response => response.json()).then(data => {

                    if(data.result) {

                        toast.success(data.response);

                        initializeTable();

                    } else {

                        toast.error(data.response);

                    }

                }).catch(error => {

                    toast.error(error);

                });

            }

        });

        event.preventDefault();

    }

    useEffect(() => {

        initializeTable();

    }, []);

    return (
        <div className="content-wrapper">
            <div className="table">
                <div className="table-button">
                    <TableFilterComponent filters={filters} changeFilters={changeFilters} initializeTable={initializeTable}></TableFilterComponent>
                    <div className="table-action">
                        <button>
                            <i className="ti ti-cloud-download mr-5"></i>Export
                        </button>
                        <button onClick={(event) => canvas(event)}>
                            <i className="ti ti-file-plus mr-5"></i>Add New
                        </button>
                    </div>
                </div>
                <div className="table-data">
                    <table className="table-full">
                        <thead>
                        <tr>
                            <th></th>
                            <th>URL</th>
                            <th>Group</th>
                            <th>Provider</th>
                            <th>Access</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            (table.rows.length === 0) && <tr>
                                <td className="text-center" colSpan={7}>No Data Available</td>
                            </tr>
                        }
                        {
                            (table.rows.length > 0) && table.rows.map((row: NawalaUrl, index: number) => {

                                return (
                                    <tr key={index}>
                                        <td>{(table.offset! + (index + 1))}</td>
                                        <td>{row.url}</td>
                                        <td>{row.group!.name}</td>
                                        <td>{row.provider}</td>
                                        <td>
                                            {
                                                row.blocked &&
                                                <span className="danger">
                                                    <i className="ti ti-inner-shadow-bottom-left-filled mr-5"></i>Blocked
                                                </span>
                                            }
                                            {
                                                !row.blocked &&
                                                <span className="success">
                                                    <i className="ti ti-inner-shadow-bottom-left-filled mr-5"></i>Online
                                                </span>
                                            }
                                        </td>
                                        <td>
                                            {
                                                row.status === Status.Active &&
                                                <span className="success">
                                                    <i className="ti ti-inner-shadow-bottom-left-filled mr-5"></i>
                                                    {Core.toStandardCase(row.status!)}
                                                </span>
                                            }
                                            {
                                                row.status === Status.Inactive &&
                                                <span className="danger">
                                                    <i className="ti ti-inner-shadow-bottom-left-filled mr-5"></i>
                                                    {Core.toStandardCase(row.status!)}
                                                </span>
                                            }
                                        </td>
                                        <td>
                                            <button onClick={(event) => canvas(event, row.id)}>
                                                <i className="ti ti-pencil-minus"></i>
                                            </button>
                                            <button onClick={(event) => remove(event, row.id!, row.url!)}>
                                                <i className="ti ti-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                );

                            })
                        }
                        </tbody>
                    </table>
                </div>
                <TablePaginationComponent table={table} tableIndex={0} changeTable={changeTable} initializeTable={initializeTable}></TablePaginationComponent>
            </div>
            <NawalaUrlDataComponent nawalaUrl={nawalaUrl} option={option} initializeTable={initializeTable} canvasClose={canvasClose}></NawalaUrlDataComponent>
        </div>
    );

}
