import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {AssetCurrency} from "../../../enums/asset/AssetCurrency";
import {AssetExchange} from "../../../enums/asset/AssetExchange";
import {AssetMarketType} from "../../../enums/asset/AssetMarketType";
import {AssetSecType} from "../../../enums/asset/AssetSecType";
import {Status} from "../../../enums/core/Status";
import {Core} from "../../../libraries/Core";
import {Rest} from "../../../libraries/Rest";
import {Asset} from "../../../models/asset/Asset";
import {AssetMarket} from "../../../models/asset/AssetMarket";
import {AssetSaveRequest} from "../../../models/asset/AssetSaveRequest";
import {AssetSec} from "../../../models/asset/AssetSec";


// @ts-ignore
export default function AssetDataComponent({asset, initializeTable, canvasClose}) {

    const [currency, changeCurrency] = useState<string>('');
    const [exchange, changeExchange] = useState<string>('');
    const [marketType, changeMarketType] = useState<string>('');
    const [name, changeName] = useState<string>('');
    const [secType, changeSecType] = useState<string>('');
    const [status, changeStatus] = useState<string>('');
    const [symbol, changeSymbol] = useState<string>('');

    const close = (event: any) => {

        canvasClose();

        event.preventDefault();

    }

    const save = (event: any): void => {

        let save: boolean = true;
        let response: string = '';

        if(save) {

            if(name.match(/^[A-Za-z0-9,]+$/)) {

                save = false;
                response = 'Name can\'t contain special characters';

            }

        }

        if(save) {

            fetch(process.env.REACT_APP_API_URL + '/asset/save/', Rest.initializeRequest(
                new AssetSaveRequest(
                    undefined,
                    undefined,
                    Core.initializeAuthentication(),
                    new Asset(
                        asset.id,
                        undefined,
                        undefined,
                        AssetCurrency[currency as keyof typeof AssetCurrency],
                        [],
                        AssetExchange[exchange as keyof typeof AssetExchange],
                        new AssetMarket(
                            0.0,
                            AssetMarketType[marketType as keyof typeof AssetMarketType]
                        ),
                        name,
                        new AssetSec(
                            AssetSecType[secType as keyof typeof AssetSecType]
                        ),
                        Status[status as keyof typeof Status],
                        symbol
                    )
                ),
                '/asset/save/'
            )).then(response => response.json()).then(data => {

                if(data.result) {

                    initializeTable();

                    toast.success(data.response);

                } else {

                    toast.error(data.response);

                }

            }).catch(error => {

                toast.error(error);

            });

        } else {

            toast.error(response);

        }

        event.preventDefault();

    }

    useEffect(() => {

        if(asset.id !== undefined) {

            changeCurrency(asset.currency.toString());
            changeExchange(asset.exchange.toString());
            changeMarketType(asset.market.type.toString());
            changeName(asset.name);
            changeSecType(asset.sec.type.toString());
            changeStatus(asset.status.toString());
            changeSymbol(asset.symbol);

        } else {

            changeCurrency('');
            changeExchange('');
            changeMarketType('');
            changeName('');
            changeSecType('');
            changeStatus('');
            changeSymbol('');

        }

    }, [asset]);

    return (
        <div className="canvas-wrapper">
            <div className="canvas-data">
                <div className="canvas-data-title">
                    <h5><i className="ti ti-coin mr-5"></i>Asset</h5>
                    <button onClick={(event) => close(event)}>
                        <i className="ti ti-x"></i>
                    </button>
                </div>
                <div className="canvas-data-form">
                    <div className="canvas-data-section">
                        <i className="ti ti-database-search mr-5"></i>Search Asset
                    </div>
                    <div className="canvas-data-field">
                        <div className="ticker-input">
                            <TextField label="Symbol" type="text" value={symbol} onChange={(event) => changeSymbol(event.target.value.toUpperCase())} variant="outlined"/>
                        </div>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Name" type="text" value={name} onChange={(event) => changeName(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="asset-market-type">Market Type</InputLabel>
                            <Select labelId="asset-market-type" label="Market Type" value={marketType} onChange={(event) => changeMarketType(event.target.value)}>
                                {
                                    Object.keys(AssetMarketType).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="asset-sec-type">SEC Type</InputLabel>
                            <Select labelId="asset-sec-type" label="SEC Type" value={secType} onChange={(event) => changeSecType(event.target.value)}>
                                {
                                    Object.keys(AssetSecType).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="asset-exchange">Exchange</InputLabel>
                            <Select labelId="asset-exchange" label="Exchange" value={exchange} onChange={(event) => changeExchange(event.target.value)}>
                                {
                                    Object.keys(AssetExchange).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="asset-currency">Currency</InputLabel>
                            <Select labelId="asset-currency" label="Currency" value={currency} onChange={(event) => changeCurrency(event.target.value)}>
                                {
                                    Object.keys(AssetCurrency).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="asset-status">Status</InputLabel>
                            <Select labelId="asset-status" label="Status" value={status} onChange={(event) => changeStatus(event.target.value)}>
                                {
                                    Object.keys(Status).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="canvas-data-action">
                    <button onClick={(event) => save(event)}>
                        <i className="ti ti-cloud-upload mr-5"></i>Save
                    </button>
                </div>
            </div>
        </div>
    );

}
