import {VpnServer} from "../server/VpnServer";


export class VpnAccountOption {

    public servers: VpnServer[] | undefined;


    constructor(servers: VpnServer[] | undefined) {

        this.servers = servers;

    }

}
