import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Country} from "../../../../enums/core/Country";
import {Status} from "../../../../enums/core/Status";
import {VpnServerProvider} from "../../../../enums/vpn/server/VpnServerProvider";
import {Core} from "../../../../libraries/Core";
import {Rest} from "../../../../libraries/Rest";
import {VpnServer} from "../../../../models/vpn/server/VpnServer";
import {VpnServerSaveRequest} from "../../../../models/vpn/server/VpnServerSaveRequest";


// @ts-ignore
export default function VpnServerDataComponent({vpnServer, initializeTable, canvasClose}) {

    const [hostname, changeHostname] = useState<string>('');
    const [identity, changeIdentity] = useState<string>('');
    const [ip, changeIp] = useState<string>('');
    const [location, changeLocation] = useState<string>('');
    const [name, changeName] = useState<string>('');
    const [password, changePassword] = useState<string>('');
    const [provider, changeProvider] = useState<string>('');
    const [status, changeStatus] = useState<string>('');
    const [url, changeUrl] = useState<string>('');
    const [username, changeUsername] = useState<string>('');

    const close = (event: any) => {

        canvasClose();

        event.preventDefault();

    }

    const save = (event: any): void => {

        fetch(process.env.REACT_APP_API_URL + '/vpn/server/save/', Rest.initializeRequest(
            new VpnServerSaveRequest(
                undefined,
                undefined,
                Core.initializeAuthentication(),
                new VpnServer(
                    vpnServer.id,
                    undefined,
                    undefined,
                    hostname,
                    identity,
                    ip,
                    Country[location as keyof typeof Country],
                    name,
                    password,
                    VpnServerProvider[provider as keyof typeof VpnServerProvider],
                    Status[status as keyof typeof Status],
                    url,
                    username
                )
            ),
            '/vpn/server/save/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                initializeTable();

                toast.success(data.response);

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

        event.preventDefault();

    }

    useEffect(() => {

        if(vpnServer.id !== undefined) {

            changeHostname(vpnServer.hostname);
            changeIdentity(vpnServer.identity);
            changeIp(vpnServer.ip);
            changeLocation(vpnServer.location.toString());
            changeName(vpnServer.name);
            changePassword(vpnServer.password);
            changeProvider(vpnServer.provider.toString());
            changeStatus(vpnServer.status.toString());
            changeUrl(vpnServer.url);
            changeUsername(vpnServer.username);

        } else {

            changeHostname('');
            changeIdentity('');
            changeIp('');
            changeLocation('');
            changeName('');
            changePassword('');
            changeProvider('');
            changeStatus('');
            changeUrl('');
            changeUsername('');

        }

    }, [vpnServer]);

    return (
        <div className="canvas-wrapper">
            <div className="canvas-data">
                <div className="canvas-data-title">
                    <h5><i className="ti ti-world-www mr-5"></i>VPN Server</h5>
                    <button onClick={(event) => close(event)}>
                        <i className="ti ti-x"></i>
                    </button>
                </div>
                <div className="canvas-data-form">
                    <div className="canvas-data-section">
                        <i className="ti ti-creative-commons-nd mr-5"></i>General
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Name" type="text" value={name} onChange={(event) => changeName(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Hostname" type="text" value={hostname} onChange={(event) => changeHostname(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Identity" type="text" value={identity} onChange={(event) => changeIdentity(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="IP" type="text" value={ip} onChange={(event) => changeIp(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="vpn-server-provider">Provider</InputLabel>
                            <Select labelId="vpn-server-provider" label="Provider" value={provider} onChange={(event) => changeProvider(event.target.value)}>
                                {
                                    Object.keys(VpnServerProvider).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="vpn-server-location">Location</InputLabel>
                            <Select labelId="vpn-server-location" label="Location" value={location} onChange={(event) => changeLocation(event.target.value)}>
                                {
                                    Object.keys(Country).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="vpn-server-status">Status</InputLabel>
                            <Select labelId="vpn-server-status" label="Status" value={status} onChange={(event) => changeStatus(event.target.value)}>
                                {
                                    Object.keys(Status).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="canvas-data-section">
                        <i className="ti ti-user-shield mr-5"></i>Credential
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="URL" type="text" value={url} onChange={(event) => changeUrl(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Username" type="text" value={username} onChange={(event) => changeUsername(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Password" type="password" value={password} onChange={(event) => changePassword(event.target.value)} variant="outlined"/>
                    </div>
                </div>
                <div className="canvas-data-action">
                    <button onClick={(event) => save(event)}>
                        <i className="ti ti-cloud-upload mr-5"></i>Save
                    </button>
                </div>
            </div>
        </div>
    );

}
