import React from "react";


export default function IndexComponent() {

    return (
        <div className="content-wrapper">
            Dashboard
        </div>
    );

}
