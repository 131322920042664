import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {DateRangePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {createChart, IChartApi, ISeriesApi, LineStyle, Time} from "lightweight-charts";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {AssetMarketType} from "../../../enums/asset/AssetMarketType";
import {DataRange} from "../../../enums/data/DataRange";
import {DataTimeframe} from "../../../enums/data/DataTimeframe";
import {DataType} from "../../../enums/data/DataType";
import {Core} from "../../../libraries/Core";
import {Rest} from "../../../libraries/Rest";
import {Asset} from "../../../models/asset/Asset";
import {AssetChartRequest} from "../../../models/asset/AssetChartRequest";
import {DataAsset} from "../../../models/data/asset/DataAsset";


export default function AssetChartComponent() {

    const {market, name} = useParams();

    const [asset, changeAsset] = useState<Asset>(Core.initializeAsset());
    const [dateRange, changeDateRange] = useState<Date[]>([]);
    const [range, changeRange] = useState<DataRange>(DataRange.SixMonths);
    const [timeframe, changeTimeframe] = useState<DataTimeframe>(DataTimeframe.Daily);
    const [type, changeType] = useState<DataType>(DataType.Candlestick);

    let chartTradingView: IChartApi | undefined = undefined;
    let series: ISeriesApi<'Candlestick', Time> | undefined = undefined;
    let volumes: ISeriesApi<'Histogram', Time> | undefined = undefined;

    const filterDateRange = (value: any) => {

        let dateRange: Date[] = [];

        value.forEach((item: any) => {

            if(item !== null) {

                dateRange.push(item.$d);

            }

        });

        changeDateRange(dateRange);

    }

    const initializeChart = () => {

        let start: Date = Core.initializeDataAssetDetailRange(range!);
        let end: Date = new Date();

        if(dateRange.length > 0) {

            start = dateRange[0];
            end = dateRange[1];

        }

        fetch(process.env.REACT_APP_API_URL + '/asset/chart/', Rest.initializeRequest(
            new AssetChartRequest(undefined, undefined, end, AssetMarketType[Core.toCapital(market!) as keyof typeof AssetMarketType], decodeURIComponent(name!), start, timeframe, type),
            '/asset/chart/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                if(data.asset !== null) {

                    changeAsset(data.asset);

                } else {

                    changeAsset(Core.initializeAsset());

                }

                if(data.data !== null) {

                    let candlesticks: any[] = [];
                    let histograms: any[] = [];
                    let lastDate: Date | undefined = undefined;
                    let lastClose: number | undefined = undefined;

                    data.data.map((dataAsset: DataAsset) => {

                        candlesticks.push({
                            'close': parseFloat(dataAsset.price!.close!.toFixed(2)),
                            'high': parseFloat(dataAsset.price!.high!.toFixed(2)),
                            'low': parseFloat(dataAsset.price!.low!.toFixed(2)),
                            'open': parseFloat(dataAsset.price!.open!.toFixed(2)),
                            'time': Core.toDatetime(dataAsset.date!).getTime() / 1000
                        });
                        histograms.push({
                            'color': dataAsset.price!.open! < dataAsset.price!.close! ? 'rgba(0, 150, 136, 0.5)' : 'rgba(255, 82, 82, 0.5)',
                            'time': Core.toDatetime(dataAsset.date!).getTime() / 1000,
                            'value': parseFloat(dataAsset.volume!.toFixed(2))
                        });

                        lastDate = Core.toDatetime(dataAsset.date!);
                        lastClose = dataAsset.price!.close!;

                    });

                    series!.setData(candlesticks);
                    volumes!.setData(histograms);
                    chartTradingView!.timeScale().fitContent();

                    if(lastDate !== undefined && lastClose !== undefined) {

                        let increment: number = 1

                        data.predictions.forEach(function(prediction: number) {

                            lastDate!.setDate(lastDate!.getDate() + increment);
                            let date: Date = new Date(lastDate!);

                            let high: number = prediction!;

                            if(prediction < lastClose!) {

                                high = lastClose!;

                            }

                            let low: number = lastClose!;

                            if(lastClose! > prediction) {

                                low = prediction;

                            }

                            lastClose = prediction;

                        });

                    }

                }

            }

        }).catch(error => {

            toast.error(error);

        });

    }

    useEffect(() => {

        if(chartTradingView === undefined) {

            setTimeout(function() {

                let element: HTMLElement = document.getElementById('trading-view-chart') as HTMLElement;
                element.innerHTML = '';
                // eslint-disable-next-line react-hooks/exhaustive-deps
                chartTradingView = createChart(element, {
                    'grid': {
                        'horzLines': {
                            'color': '#3a3a3a',
                            'style': LineStyle.Dashed
                        },
                        'vertLines': {
                            'color': '#3a3a3a',
                            'style': LineStyle.Dashed
                        }
                    },
                    'height': 500,
                    'layout': {
                        'background': {
                            'color': '#161616'
                        },
                        'textColor': '#fff'
                    },
                    'timeScale': {
                        'secondsVisible': false,
                        'timeVisible': true
                    },
                    'width': parseFloat(window.getComputedStyle(element).width)
                });
                // eslint-disable-next-line react-hooks/exhaustive-deps
                series = chartTradingView.addCandlestickSeries();
                // eslint-disable-next-line react-hooks/exhaustive-deps
                volumes = chartTradingView.addHistogramSeries({
                    'priceScaleId': 'scale-volume'
                });
                volumes.priceScale().applyOptions({
                    'scaleMargins': {
                        'bottom': 0,
                        'top': 0.85
                    }
                });

                initializeChart();

            }, 1000);

        } else {

            initializeChart();

        }

    }, [dateRange, range, timeframe, type]);

    return (
        <div className="content-wrapper">
            <div className="chart-boxed">
                <div className="detail-boxed-chart-title">
                    <Link className="back" to={'/assets/' + market + '/' + name + '/'}>
                        <i className="ti ti-arrow-left"></i>
                    </Link>
                    {
                        (asset.name !== undefined && asset.symbol !== undefined) &&
                        <h3>{asset.name + ' (' + asset.symbol + ')'}</h3>
                    }
                    {
                        (asset.name === undefined || asset.symbol === undefined) &&
                        <h3>&nbsp;</h3>
                    }
                    <div className="option">
                        <FormControl fullWidth>
                            <InputLabel id="asset-chart-timeframe">Timeframe</InputLabel>
                            <Select labelId="asset-chart-timeframe" value={timeframe} onChange={(event) => changeTimeframe(DataTimeframe[event.target.value as keyof typeof DataTimeframe])}>
                                <MenuItem value={DataTimeframe.FiveMinutes}>5 Minutes</MenuItem>
                                <MenuItem value={DataTimeframe.FifteenMinutes}>15 Minutes</MenuItem>
                                <MenuItem value={DataTimeframe.Hourly}>Hourly</MenuItem>
                                <MenuItem value={DataTimeframe.Daily}>Daily</MenuItem>
                                <MenuItem value={DataTimeframe.Weekly}>Weekly</MenuItem>
                                <MenuItem value={DataTimeframe.Monthly}>Monthly</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="option">
                        <FormControl fullWidth>
                            <InputLabel id="asset-chart-type">Type</InputLabel>
                            <Select labelId="asset-chart-type" value={type} onChange={(event) => changeType(DataType[event.target.value as keyof typeof DataType])}>
                                <MenuItem value={DataType.Candlestick}>Candlestick</MenuItem>
                                <MenuItem value={DataType.HeikinAshi}>Heikin Ashi</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="option">
                        <FormControl fullWidth>
                            <InputLabel id="asset-chart-range">Range</InputLabel>
                            <Select labelId="asset-chart-range" value={range} onChange={(event) => changeRange(DataRange[event.target.value as keyof typeof DataRange])}>
                                <MenuItem value={DataRange.OneWeek}>1 Week</MenuItem>
                                <MenuItem value={DataRange.OneMonth}>1 Month</MenuItem>
                                <MenuItem value={DataRange.ThreeMonths}>3 Months</MenuItem>
                                <MenuItem value={DataRange.SixMonths}>6 Months</MenuItem>
                                <MenuItem value={DataRange.OneYear}>1 Year</MenuItem>
                                <MenuItem value={DataRange.ThreeYears}>3 Years</MenuItem>
                                <MenuItem value={DataRange.FiveYears}>5 Years</MenuItem>
                                <MenuItem value={DataRange.Max}>Max</MenuItem>
                                <MenuItem value={DataRange.Custom}>Custom</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {
                        range === DataRange.Custom &&
                        <div className="option option-date-range">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateRangePicker localeText={{start: 'Date Start', end: 'Date End'}}
                                                 onChange={(value) => filterDateRange(value)}></DateRangePicker>
                            </LocalizationProvider>
                        </div>
                    }
                </div>
                <div id="trading-view-chart"></div>
            </div>
        </div>
    );

}
