import React, {Fragment, useEffect} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {AssetDataLearn} from "../../../enums/asset/AssetDataLearn";
import {AssetDataStatus} from "../../../enums/asset/AssetDataStatus";
import {DataTimeframe} from "../../../enums/data/DataTimeframe";
import {UserType} from "../../../enums/user/UserType";
import {Core} from "../../../libraries/Core";
import {Rest} from "../../../libraries/Rest";
import {Time} from "../../../libraries/Time";
import {AssetData} from "../../../models/asset/AssetData";
import {AssetLearnRequest} from "../../../models/asset/AssetLearnRequest";
import {AssetSynchronizeRequest} from "../../../models/asset/AssetSynchronizeRequest";
import {DataRequest} from "../../../models/core/DataRequest";


// @ts-ignore
export default function AssetActionComponent({account, row, initializeTable}) {

    const learn = (event: any, id: string, timeframe: DataTimeframe) => {

        fetch(process.env.REACT_APP_API_URL + '/asset/learn/', Rest.initializeRequest(
            new AssetLearnRequest(undefined, undefined, Core.initializeAuthentication(), id, timeframe),
            '/asset/learn/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                toast.success(data.response);

                initializeTable();

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

        event.preventDefault();

    }

    const remove = (event: any, id: string, ticker: string) => {

        Swal.fire(Core.initializeSwalDelete('Are you sure want to delete?', ticker)).then((result) => {

            if(result.isConfirmed) {

                fetch(process.env.REACT_APP_API_URL + '/asset/delete/', Rest.initializeRequest(
                    new DataRequest(undefined, undefined, Core.initializeAuthentication(), id),
                    '/asset/delete/'
                )).then(response => response.json()).then(data => {

                    if(data.result) {

                        toast.success(data.response);

                        initializeTable();

                    } else {

                        toast.error(data.response);

                    }

                }).catch(error => {

                    toast.error(error);

                });

            }

        });

        event.preventDefault();

    }

    const synchronize = (event: any, id: string, timeframe: DataTimeframe) => {

        fetch(process.env.REACT_APP_API_URL + '/asset/synchronize/', Rest.initializeRequest(
            new AssetSynchronizeRequest(undefined, undefined, Core.initializeAuthentication(), id, timeframe),
            '/asset/synchronize/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                toast.success(data.response);

                initializeTable();

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

        event.preventDefault();

    }

    const synchronizeClose = (element: HTMLElement) => {

        let opacity: number = 1;
        let decrement: number = 1 / 20;
        let fade = setInterval(() => {

            if(opacity === 0) {

                clearInterval(fade);
                element.style.display = 'none';

            } else {

                opacity -= decrement;

                if(opacity < 0) {

                    opacity = 0;

                }

                element.style.opacity = opacity.toString();

            }

        }, 1);

    }

    const synchronizeToggle = (event: any, id: string) => {

        let actionPopup: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('table-action-popup') as HTMLCollectionOf<HTMLElement>);

        if(actionPopup.length > 0) {

            Array.from(actionPopup).forEach(function(element: HTMLElement, index: number) {

                let dataId: string = element.getAttribute('data-id') as string;

                if(dataId === id) {

                    if(window.getComputedStyle(element).getPropertyValue('display') === 'block') {

                        synchronizeClose(element);

                    } else {

                        element.style.display = 'block';

                        let opacity: number = 0;
                        let increment: number = 1 / 20;
                        let fade = setInterval(() => {

                            if(opacity === 1) {

                                clearInterval(fade);

                            } else {

                                opacity += increment;

                                if(opacity > 1) {

                                    opacity = 1;

                                }

                                element.style.opacity = opacity.toString();

                            }

                        }, 1);

                    }

                }

            });

        }

        event.preventDefault();

    }

    useEffect((): any => {

        document.addEventListener('click', function(event) {

            let actionButton: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('table-action-button') as HTMLCollectionOf<HTMLElement>);
            let actionPopup: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('table-action-popup') as HTMLCollectionOf<HTMLElement>);

            if(actionButton.length > 0 && actionPopup.length > 0) {

                Array.from(actionPopup).forEach(function(element: HTMLElement, index: number) {

                    if(!actionButton[index].contains(event.target as Node) && !element.contains(event.target as Node)) {

                        synchronizeClose(element);

                    }

                });

            }

        });

    }, []);

    return (
        <td>
            {
                (account.type === UserType.Owner || account.type === UserType.Administrator) &&
                <Fragment>
                    <button className="table-action-button" onClick={(event) => synchronizeToggle(event, row.id)}>
                        <i className="ti ti-refresh-alert"></i>
                    </button>
                    <div className="table-action-popup" data-id={row.id}>
                        <div className="table-action-wrapper">
                            {
                                (row.data.length > 0) && row.data.map((data: AssetData, index: number) => {

                                    return (
                                        <div key={index} className="table-action-row">
                                            {
                                                data.timeframe === DataTimeframe.FiveMinutes &&
                                                <p className="table-action-title">5 mins :</p>
                                            }
                                            {
                                                data.timeframe === DataTimeframe.FifteenMinutes &&
                                                <p className="table-action-title">15 mins :</p>
                                            }
                                            {
                                                data.timeframe === DataTimeframe.ThirtyMinutes &&
                                                <p className="table-action-title">30 mins :</p>
                                            }
                                            {
                                                data.timeframe === DataTimeframe.Hourly &&
                                                <p className="table-action-title">Hourly :</p>
                                            }
                                            {
                                                data.timeframe === DataTimeframe.FourHours &&
                                                <p className="table-action-title">4 hours :</p>
                                            }
                                            {
                                                data.timeframe === DataTimeframe.Daily &&
                                                <p className="table-action-title">Daily :</p>
                                            }
                                            {
                                                data.timeframe === DataTimeframe.Weekly &&
                                                <p className="table-action-title">Weekly :</p>
                                            }
                                            {
                                                data.timeframe === DataTimeframe.Monthly &&
                                                <p className="table-action-title">Monthly :</p>
                                            }
                                            <div>
                                                {
                                                    data.status === AssetDataStatus.Unsynchronized &&
                                                    <button className="unsynchronized" onClick={(event) => synchronize(event, row.id!, data.timeframe!)}>
                                                        <i className="ti ti-refresh-alert mr-5"></i>Synchronize
                                                    </button>
                                                }
                                                {
                                                    data.status === AssetDataStatus.Synchronizing &&
                                                    <button className="synchronizing" disabled={true}>
                                                        <i className="ti ti-refresh-alert mr-5"></i>Synchronizing
                                                    </button>
                                                }
                                                {
                                                    data.status === AssetDataStatus.Synchronized &&
                                                    <Fragment>
                                                        <button className="synchronized" disabled={true}>
                                                            <i className="ti ti-refresh-alert mr-5"></i>Synchronized
                                                        </button>
                                                        {
                                                            data.learn === AssetDataLearn.Unlearned &&
                                                            <button className="unsynchronized" onClick={(event) => learn(event, row.id!, data.timeframe!)}>
                                                                <i className="ti ti-microscope mr-5"></i>AI Learn
                                                            </button>
                                                        }
                                                        {
                                                            data.learn === AssetDataLearn.Learning &&
                                                            <button className="synchronizing" disabled={true}>
                                                                <i className="ti ti-microscope mr-5"></i>AI Learning
                                                            </button>
                                                        }
                                                        {
                                                            data.learn === AssetDataLearn.Learned &&
                                                            <button className="synchronized" disabled={true}>
                                                                <i className="ti ti-microscope mr-5"></i>AI Learned
                                                            </button>
                                                        }
                                                    </Fragment>
                                                }
                                                <p>{'Last Date : ' + Time.toDateTimeString(data.last!)}</p>
                                            </div>
                                        </div>
                                    );

                                })
                            }
                        </div>
                    </div>
                </Fragment>
            }
            <Link to={'/assets/' + row.market!.type!.toLowerCase() + '/' + encodeURIComponent(row.name!.toLowerCase())}>
                <i className="ti ti-list-search"></i>
            </Link>
            <button onClick={(event) => remove(event, row.id!, row.symbol!)}>
                <i className="ti ti-trash"></i>
            </button>
        </td>
    );

}
