import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import {Country} from "../../../enums/core/Country";
import {Core} from "../../../libraries/Core";
import {Rest} from "../../../libraries/Rest";
import {User} from "../../../models/user/User";
import {UserAddress} from "../../../models/user/UserAddress";
import {UserSaveRequest} from "../../../models/user/UserSaveRequest";
import AccountNavigationComponent from "./AccountNavigationComponent";


export default function AccountAddressComponent() {

    // @ts-ignore
    const {account} = useOutletContext();

    const [addressCity, changeAddressCity] = useState<string>('');
    const [addressCountry, changeAddressCountry] = useState<string>('');
    const [addressDistrict, changeAddressDistrict] = useState<string>('');
    const [addressState, changeAddressState] = useState<string>('');
    const [addressStreet, changeAddressStreet] = useState<string>('');
    const [addressZip, changeAddressZip] = useState<string>('');

    const save = (event: any): void => {

        fetch(process.env.REACT_APP_API_URL + '/user/change-address/', Rest.initializeRequest(
            new UserSaveRequest(
                undefined,
                undefined,
                Core.initializeAuthentication(),
                new User(
                    account.id,
                    undefined,
                    undefined,
                    new UserAddress(
                        addressCity,
                        Country[addressCountry as keyof typeof Country],
                        addressDistrict,
                        addressState,
                        addressStreet,
                        addressZip
                    ),
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined
                )
            ),
            '/user/change-address/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                toast.success(data.response);

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

        event.preventDefault();

    }

    useEffect(() => {

        if(account.id !== undefined) {

            if(account.address.city !== null) {

                changeAddressCity(account.address.city);

            }

            changeAddressCountry(account.address.country);

            if(account.address.district !== null) {

                changeAddressDistrict(account.address.district);

            }

            if(account.address.state !== null) {

                changeAddressState(account.address.state);

            }

            if(account.address.street !== null) {

                changeAddressStreet(account.address.street);

            }

            if(account.address.zip !== null) {

                changeAddressZip(account.address.zip);

            }

        } else {

            changeAddressCity('');
            changeAddressCountry('');
            changeAddressDistrict('');
            changeAddressState('');
            changeAddressStreet('');
            changeAddressZip('');

        }

    }, [account]);

    return (
        <div className="content-wrapper">
            <div className="edit-boxed">
                <AccountNavigationComponent></AccountNavigationComponent>
                <div className="edit-boxed-content">
                    <div className="edit-boxed-content-wrapper">
                        <h5><i className="ti ti-map-pins mr-5"></i>Address</h5>
                        <div className="edit-boxed-content-form">
                            <div className="edit-boxed-content-column">
                                <div className="edit-boxed-content-field">
                                    <TextField label="Street" type="text" value={addressStreet} onChange={(event) => changeAddressStreet(event.target.value)} variant="outlined"/>
                                </div>
                                <div className="edit-boxed-content-field">
                                    <TextField label="City" type="text" value={addressCity} onChange={(event) => changeAddressCity(event.target.value)} variant="outlined"/>
                                </div>
                                <div className="edit-boxed-content-field">
                                    <TextField label="District" type="text" value={addressDistrict} onChange={(event) => changeAddressDistrict(event.target.value)} variant="outlined"/>
                                </div>
                            </div>
                            <div className="edit-boxed-content-column">
                                <div className="edit-boxed-content-field">
                                    <TextField label="State" type="text" value={addressState} onChange={(event) => changeAddressState(event.target.value)} variant="outlined"/>
                                </div>
                                <div className="edit-boxed-content-field">
                                    <FormControl fullWidth>
                                        <InputLabel id="user-country">Country</InputLabel>
                                        <Select labelId="user-country" label="Country" value={addressCountry} onChange={(event) => changeAddressCountry(event.target.value)}>
                                            {
                                                Object.keys(Country).map((item: any, index: number) => {

                                                    return (
                                                        <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                                    );

                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="edit-boxed-content-field">
                                    <TextField label="ZIP" type="text" value={addressZip} onChange={(event) => changeAddressZip(event.target.value)} variant="outlined"/>
                                </div>
                            </div>
                        </div>
                        <div className="edit-boxed-content-button">
                            <button onClick={(event) => save(event)}>
                                <i className="ti ti-cloud-upload mr-5"></i>Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
