import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {DateRangePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs';
import React, {useEffect} from "react";
import {TableFilterType} from "../../enums/core/table/TableFilterType";
import {Core} from "../../libraries/Core";
import {TableFilter} from "../../models/core/table/TableFilter";


// @ts-ignore
export default function TableFilterComponent({filters, changeFilters, initializeTable}) {

    const filter = (event: any) => {

        initializeTable();

        filterClose();

        event.preventDefault();

    }

    const filterClose = () => {

        let filter: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('table-filter-wrapper') as HTMLCollectionOf<HTMLElement>);

        if(filter.length > 0) {

            let opacity: number = 1;
            let decrement: number = 1 / 20;
            let fade = setInterval(() => {

                if(opacity === 0) {

                    clearInterval(fade);

                    if(filter.length > 0) {

                        filter[0].style.display = 'none';

                    }

                } else {

                    opacity -= decrement;

                    if(opacity < 0) {

                        opacity = 0;

                    }

                    if(filter.length > 0) {

                        filter[0].style.opacity = opacity.toString();

                    }

                }

            }, 1);

        }

    }

    const filterOpen = () => {

        let filter: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('table-filter-wrapper') as HTMLCollectionOf<HTMLElement>);

        if(filter.length > 0) {

            filter[0].style.display = 'block';

            let opacity: number = 0;
            let increment: number = 1 / 20;
            let fade = setInterval(() => {

                if(opacity === 1) {

                    clearInterval(fade);

                } else {

                    opacity += increment;

                    if(opacity > 1) {

                        opacity = 1;

                    }

                    filter[0].style.opacity = opacity.toString();

                }

            }, 1);

        }

    }

    const setValue = (index: number, value: any) => {

        if(filters[index].type === TableFilterType.DateRange) {

            let dateRange: Date[] = [];

            value.forEach((item: any) => {

                if(item !== null) {

                    dateRange.push(item.$d);

                }

            });

            filters[index].value = dateRange;

        } else {

            filters[index].value = value;

        }

        changeFilters(Core.renderFilter(filters));

    }

    const toggle = (event: any) => {

        let filter: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('table-filter-wrapper') as HTMLCollectionOf<HTMLElement>);

        if(filter.length > 0) {

            if(filter[0].style.display === 'block') {

                filterClose();

            } else {

                filterOpen();

            }

        }

        event.preventDefault();

    }

    useEffect((): any => {

        /*document.addEventListener('click', function(event) {

            let filterToggle: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('table-filter-toggle') as HTMLCollectionOf<HTMLElement>);
            let filterWrapper: HTMLCollectionOf<HTMLElement> = (document.getElementsByClassName('table-filter-wrapper') as HTMLCollectionOf<HTMLElement>);

            if(filterToggle.length > 0 && filterWrapper.length > 0) {

                if(!filterToggle[0].contains(event.target as Node) && !filterWrapper[0].contains(event.target as Node)) {

                    filterClose();

                }

            }

        });*/

    }, []);

    return (
        <div className="table-filter">
            <button className='table-filter-toggle' onClick={(event) => toggle(event)}>
                <i className="ti ti-filter mr-5"></i>Filter
            </button>
            <div className="table-filter-wrapper">
                {
                    filters.map((filter: TableFilter, index: number) => {

                        if(filter.type === TableFilterType.Boolean) {

                            return (
                                <div key={index} className="table-filter-item">
                                    <FormControl fullWidth>
                                        <InputLabel id={'filter-' + index}>{filter.name}</InputLabel>
                                        <Select labelId={'filter-' + index} label={filter.name} value={filters[index].value} onChange={(event) => setValue(index, event.target.value)}>
                                            <MenuItem value="">None</MenuItem>
                                            {
                                                filter.options!.map((item: any, index: number) => {

                                                    return (
                                                        <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                    );

                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            );

                        } else if(filter.type === TableFilterType.DateRange) {

                            return (
                                <div key={index} className="table-filter-item">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateRangePicker localeText={{start: 'Date Start', end: 'Date End'}}
                                                         onChange={(value) => setValue(index, value)}></DateRangePicker>
                                    </LocalizationProvider>
                                </div>
                            );

                        } else if(filter.type === TableFilterType.Enum) {

                            return (
                                <div key={index} className="table-filter-item">
                                    <FormControl fullWidth>
                                        <InputLabel id={'filter-' + index}>{filter.name}</InputLabel>
                                        <Select labelId={'filter-' + index} label={filter.name} value={filters[index].value} onChange={(event) => setValue(index, event.target.value)}>
                                            <MenuItem value="">None</MenuItem>
                                            {
                                                filter.options!.map((item: any, index: number) => {

                                                    return (
                                                        <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                                    );

                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            );

                        } else if(filter.type === TableFilterType.ObjectId) {

                            return (
                                <div key={index} className="table-filter-item">
                                    <FormControl fullWidth>
                                        <InputLabel id={'filter-' + index}>{filter.name}</InputLabel>
                                        <Select labelId={'filter-' + index} label={filter.name} value={filters[index].value} onChange={(event) => setValue(index, event.target.value)}>
                                            <MenuItem value="">None</MenuItem>
                                            {
                                                filter.options!.map((item: any, index: number) => {

                                                    return (
                                                        <MenuItem key={index} value={item.value}>{item.text}</MenuItem>
                                                    );

                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            );

                        } else {

                            return (
                                <div key={index} className="table-filter-item">
                                    <TextField label={filter.name} type="text" value={filters[index].value} onChange={(event) => setValue(index, event.target.value)} variant="outlined"/>
                                </div>
                            );

                        }

                    })
                }
                <div className="table-filter-button">
                    <button onClick={(event) => filter(event)}>Apply</button>
                </div>
            </div>
        </div>
    );

}
