import {Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Status} from "../../../../enums/core/Status";
import {Core} from "../../../../libraries/Core";
import {Rest} from "../../../../libraries/Rest";
import {NawalaGroup} from "../../../../models/nawala/group/NawalaGroup";
import {NawalaGroupReference} from "../../../../models/nawala/group/NawalaGroupReference";
import {NawalaUrl} from "../../../../models/nawala/url/NawalaUrl";
import {NawalaUrlSaveRequest} from "../../../../models/nawala/url/NawalaUrlSaveRequest";


// @ts-ignore
export default function NawalaUrlDataComponent({nawalaUrl, option, initializeTable, canvasClose}) {

    const [group, changeGroup] = useState<NawalaGroup | null>(null);
    const [provider, changeProvider] = useState<string>('');
    const [status, changeStatus] = useState<string>('');
    const [url, changeUrl] = useState<string>('');

    const close = (event: any) => {

        canvasClose();

        event.preventDefault();

    }

    const save = (event: any): void => {

        if(url.startsWith('https://')) {

            fetch(process.env.REACT_APP_API_URL + '/nawala/url/save/', Rest.initializeRequest(
                new NawalaUrlSaveRequest(
                    undefined,
                    undefined,
                    Core.initializeAuthentication(),
                    new NawalaUrl(
                        nawalaUrl.id,
                        undefined,
                        undefined,
                        undefined,
                        new NawalaGroupReference(
                            group!.id,
                            group!.key,
                            group!.name
                        ),
                        provider,
                        Status[status as keyof typeof Status],
                        url
                    )
                ),
                '/nawala/url/save/'
            )).then(response => response.json()).then(data => {

                if(data.result) {

                    initializeTable();

                    toast.success(data.response);

                } else {

                    toast.error(data.response);

                }

            }).catch(error => {

                toast.error(error);

            });

        } else {

            toast.error('Please input https:// in the beginning');

        }

        event.preventDefault();

    }

    useEffect(() => {

        if(nawalaUrl.id !== undefined) {

            option.groups.forEach((group: NawalaGroup) => {

                if(group.id === nawalaUrl.group.id) {

                    changeGroup(group);

                }

            });

            changeProvider(nawalaUrl.provider);
            changeStatus(nawalaUrl.status.toString());
            changeUrl(nawalaUrl.url);

        } else {

            changeGroup(null);
            changeProvider('');
            changeStatus('');
            changeUrl('');

        }

        if(option.groups.length === 1) {

            changeGroup(option.groups[0]);

        }

    }, [nawalaUrl]);

    return (
        <div className="canvas-wrapper">
            <div className="canvas-data">
                <div className="canvas-data-title">
                    <h5><i className="ti ti-world-www mr-5"></i>Nawala URL</h5>
                    <button onClick={(event) => close(event)}>
                        <i className="ti ti-x"></i>
                    </button>
                </div>
                <div className="canvas-data-form">
                    <div className="canvas-data-section">
                        <i className="ti ti-creative-commons-nd mr-5"></i>General
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="URL" type="text" value={url} onChange={(event) => changeUrl(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Provider" type="text" value={provider} onChange={(event) => changeProvider(event.target.value)} variant="outlined"/>
                    </div>
                    {
                        option.groups.length > 1 &&
                        <div className="canvas-data-field">
                            <Autocomplete options={option.groups} getOptionLabel={(group) => group.name!} renderInput={(parameter) =>
                                <TextField {...parameter} label="Group"></TextField>} value={group} onChange={(event, value) => changeGroup(value)}></Autocomplete>
                        </div>
                    }
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="nawala-url-status">Status</InputLabel>
                            <Select labelId="nawala-url-status" label="Status" value={status} onChange={(event) => changeStatus(event.target.value)}>
                                {
                                    Object.keys(Status).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="canvas-data-action">
                    <button onClick={(event) => save(event)}>
                        <i className="ti ti-cloud-upload mr-5"></i>Save
                    </button>
                </div>
            </div>
        </div>
    );

}
