export class AssetDetailPredictionValue {

    public probability: number | undefined;

    public timestamp: Date | undefined;

    public value: number | undefined;


    constructor(probability: number | undefined, timestamp: Date | undefined, value: number | undefined) {

        this.probability = probability;
        this.timestamp = timestamp;
        this.value = value;

    }

}
