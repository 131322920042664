import {TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import {Core} from "../../../libraries/Core";
import {Rest} from "../../../libraries/Rest";
import {User} from "../../../models/user/User";
import {UserContact} from "../../../models/user/UserContact";
import {UserSaveRequest} from "../../../models/user/UserSaveRequest";
import AccountNavigationComponent from "./AccountNavigationComponent";


export default function AccountContactComponent() {

    // @ts-ignore
    const {account} = useOutletContext();

    const [contactDiscord, changeContactDiscord] = useState<string>('');
    const [contactEmail, changeContactEmail] = useState<string>('');
    const [contactPhone, changeContactPhone] = useState<string>('');
    const [contactSkype, changeContactSkype] = useState<string>('');
    const [contactSlack, changeContactSlack] = useState<string>('');
    const [contactTelegram, changeContactTelegram] = useState<string>('');
    const [contactWechat, changeContactWechat] = useState<string>('');
    const [contactWhatsapp, changeContactWhatsapp] = useState<string>('');

    const save = (event: any): void => {

        fetch(process.env.REACT_APP_API_URL + '/user/change-contact/', Rest.initializeRequest(
            new UserSaveRequest(
                undefined,
                undefined,
                Core.initializeAuthentication(),
                new User(
                    account.id,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    new UserContact(
                        contactDiscord,
                        contactEmail,
                        contactPhone,
                        contactSkype,
                        contactSlack,
                        contactTelegram,
                        contactWechat,
                        contactWhatsapp
                    ),
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined
                )
            ),
            '/user/change-contact/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                toast.success(data.response);

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

        event.preventDefault();

    }

    useEffect(() => {

        if(account.id !== undefined) {

            if(account.contact.discord !== null) {

                changeContactDiscord(account.contact.discord);

            }

            if(account.contact.email !== null) {

                changeContactEmail(account.contact.email);

            }

            if(account.contact.phone !== null) {

                changeContactPhone(account.contact.phone);

            }

            if(account.contact.skype !== null) {

                changeContactSkype(account.contact.skype);

            }

            if(account.contact.slack !== null) {

                changeContactSlack(account.contact.slack);

            }

            if(account.contact.telegram !== null) {

                changeContactTelegram(account.contact.telegram);

            }

            if(account.contact.wechat !== null) {

                changeContactWechat(account.contact.wechat);

            }

            if(account.contact.whatsapp !== null) {

                changeContactWhatsapp(account.contact.whatsapp);

            }

        } else {

            changeContactDiscord('');
            changeContactEmail('');
            changeContactPhone('');
            changeContactSkype('');
            changeContactSlack('');
            changeContactTelegram('');
            changeContactWechat('');
            changeContactWhatsapp('');

        }

    }, [account]);

    return (
        <div className="content-wrapper">
            <div className="edit-boxed">
                <AccountNavigationComponent></AccountNavigationComponent>
                <div className="edit-boxed-content">
                    <div className="edit-boxed-content-wrapper">
                        <h5><i className="ti ti-address-book mr-5"></i>Contact</h5>
                        <div className="edit-boxed-content-form">
                            <div className="edit-boxed-content-column">
                                <div className="edit-boxed-content-field">
                                    <TextField label="Email" type="text" value={contactEmail} onChange={(event) => changeContactEmail(event.target.value)} variant="outlined"/>
                                </div>
                                <div className="edit-boxed-content-field">
                                    <TextField label="Phone" type="text" value={contactPhone} onChange={(event) => changeContactPhone(event.target.value)} variant="outlined"/>
                                </div>
                                <div className="edit-boxed-content-field">
                                    <TextField label="Whatsapp" type="text" value={contactWhatsapp} onChange={(event) => changeContactWhatsapp(event.target.value)} variant="outlined"/>
                                </div>
                                <div className="edit-boxed-content-field">
                                    <TextField label="Telegram" type="text" value={contactTelegram} onChange={(event) => changeContactTelegram(event.target.value)} variant="outlined"/>
                                </div>
                            </div>
                            <div className="edit-boxed-content-column">
                                <div className="edit-boxed-content-field">
                                    <TextField label="Wechat" type="text" value={contactWechat} onChange={(event) => changeContactWechat(event.target.value)} variant="outlined"/>
                                </div>
                                <div className="edit-boxed-content-field">
                                    <TextField label="Discord" type="text" value={contactDiscord} onChange={(event) => changeContactDiscord(event.target.value)} variant="outlined"/>
                                </div>
                                <div className="edit-boxed-content-field">
                                    <TextField label="Skype" type="text" value={contactSkype} onChange={(event) => changeContactSkype(event.target.value)} variant="outlined"/>
                                </div>
                                <div className="edit-boxed-content-field">
                                    <TextField label="Slack" type="text" value={contactSlack} onChange={(event) => changeContactSlack(event.target.value)} variant="outlined"/>
                                </div>
                            </div>
                        </div>
                        <div className="edit-boxed-content-button">
                            <button onClick={(event) => save(event)}>
                                <i className="ti ti-cloud-upload mr-5"></i>Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
