import {Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Status} from "../../../../enums/core/Status";
import {Core} from "../../../../libraries/Core";
import {Rest} from "../../../../libraries/Rest";
import {TelegramReference} from "../../../../models/integration/telegram/TelegramReference";
import {NawalaGroup} from "../../../../models/nawala/group/NawalaGroup";
import {NawalaGroupAlert} from "../../../../models/nawala/group/NawalaGroupAlert";
import {NawalaGroupQuota} from "../../../../models/nawala/group/NawalaGroupQuota";
import {NawalaGroupSaveRequest} from "../../../../models/nawala/group/NawalaGroupSaveRequest";
import {User} from "../../../../models/user/User";
import {UserReference} from "../../../../models/user/UserReference";


// @ts-ignore
export default function NawalaGroupDataComponent({nawalaGroup, option, initializeTable, canvasClose}) {

    const [alertUrlChatId, changeAlertUrlChatId] = useState<number>(0);
    const [alertUrlToken, changeAlertUrlToken] = useState<string>('');
    const [interval, changeInterval] = useState<number>(0);
    const [key, changeKey] = useState<string>('');
    const [name, changeName] = useState<string>('');
    const [quotaLimit, changeQuotaLimit] = useState<number>(0);
    const [status, changeStatus] = useState<string>('');
    const [users, changeUsers] = useState<User[]>([]);

    const close = (event: any) => {

        canvasClose();

        event.preventDefault();

    }

    const save = (event: any): void => {

        let userReferences: UserReference[] = [];

        users.forEach((user: User) => {

            userReferences.push(new UserReference(user.id, user.username));

        });

        fetch(process.env.REACT_APP_API_URL + '/nawala/group/save/', Rest.initializeRequest(
            new NawalaGroupSaveRequest(
                undefined,
                undefined,
                Core.initializeAuthentication(),
                new NawalaGroup(
                    nawalaGroup.id,
                    undefined,
                    undefined,
                    new NawalaGroupAlert(
                        new TelegramReference(
                            alertUrlChatId,
                            alertUrlToken
                        )
                    ),
                    interval,
                    key,
                    name,
                    new NawalaGroupQuota(
                        quotaLimit,
                        undefined
                    ),
                    Status[status as keyof typeof Status],
                    userReferences
                )
            ),
            '/nawala/group/save/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                initializeTable();

                toast.success(data.response);

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

        event.preventDefault();

    }

    useEffect(() => {

        if(nawalaGroup.id !== undefined) {

            changeAlertUrlChatId(nawalaGroup.alert.url.chatId);
            changeAlertUrlToken(nawalaGroup.alert.url.token);
            changeInterval(nawalaGroup.interval);
            changeKey(nawalaGroup.key);
            changeName(nawalaGroup.name);
            changeQuotaLimit(nawalaGroup.quota.limit);
            changeStatus(nawalaGroup.status.toString());
            changeUsers(nawalaGroup.users);

        } else {

            changeAlertUrlChatId(0);
            changeAlertUrlToken('');
            changeInterval(0);
            changeKey('');
            changeName('');
            changeQuotaLimit(0);
            changeStatus('');
            changeUsers([]);

        }

    }, [nawalaGroup]);

    return (
        <div className="canvas-wrapper">
            <div className="canvas-data">
                <div className="canvas-data-title">
                    <h5><i className="ti ti-buildings mr-5"></i>Nawala Group</h5>
                    <button onClick={(event) => close(event)}>
                        <i className="ti ti-x"></i>
                    </button>
                </div>
                <div className="canvas-data-form">
                    <div className="canvas-data-section">
                        <i className="ti ti-creative-commons-nd mr-5"></i>General
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Name" type="text" value={name} onChange={(event) => changeName(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Key" type="text" value={key} onChange={(event) => changeKey(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <Autocomplete multiple options={option.users} getOptionLabel={(user) => user.username!} renderInput={(parameter) =>
                            <TextField {...parameter} label="Users"></TextField>} value={users} onChange={(event, value) => changeUsers(value)}></Autocomplete>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Interval" type="number" value={interval} onChange={(event) => changeInterval(parseInt(event.target.value))} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Quota" type="number" value={quotaLimit} onChange={(event) => changeQuotaLimit(parseInt(event.target.value))} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="nawala-group-status">Status</InputLabel>
                            <Select labelId="nawala-group-status" label="Status" value={status} onChange={(event) => changeStatus(event.target.value)}>
                                {
                                    Object.keys(Status).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="canvas-data-section">
                        <i className="ti ti-bell mr-5"></i>URL Alert
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Telegram Token" type="text" value={alertUrlToken} onChange={(event) => changeAlertUrlToken(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Telegram Chat ID" type="text" value={alertUrlChatId} onChange={(event) => changeAlertUrlChatId(parseInt(event.target.value))} variant="outlined"/>
                    </div>
                </div>
                <div className="canvas-data-action">
                    <button onClick={(event) => save(event)}>
                        <i className="ti ti-cloud-upload mr-5"></i>Save
                    </button>
                </div>
            </div>
        </div>
    );

}
