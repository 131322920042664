import {Box, Chip, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Status} from "../../../../enums/core/Status";
import {Core} from "../../../../libraries/Core";
import {Rest} from "../../../../libraries/Rest";
import {VpnAccount} from "../../../../models/vpn/account/VpnAccount";
import {VpnAccountConfiguration} from "../../../../models/vpn/account/VpnAccountConfiguration";
import {VpnAccountSaveRequest} from "../../../../models/vpn/account/VpnAccountSaveRequest";
import {VpnServer} from "../../../../models/vpn/server/VpnServer";


export default function VpnAccountDataComponent(
    // @ts-ignore
    {vpnAccount, vpnAccountConfiguration, option, initializeTable, canvasClose}) {

    const [limit, changeLimit] = useState<number>(0);
    const [password, changePassword] = useState<string>('');
    const [servers, changeServers] = useState<VpnServer[]>([]);
    const [status, changeStatus] = useState<string>('');
    const [username, changeUsername] = useState<string>('');

    const close = (event: any) => {

        canvasClose();

        event.preventDefault();

    }

    const serversChange = (value: any) => {

        changeServers(value);

    };

    const save = (event: any): void => {

        fetch(process.env.REACT_APP_API_URL + '/vpn/account/save/', Rest.initializeRequest(
            new VpnAccountSaveRequest(
                undefined,
                undefined,
                Core.initializeAuthentication(),
                new VpnAccount(
                    vpnAccount.id,
                    undefined,
                    undefined,
                    limit,
                    password,
                    Status[status as keyof typeof Status],
                    undefined,
                    username
                ),
                servers
            ),
            '/vpn/account/save/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                initializeTable();

                toast.success(data.response);

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

        event.preventDefault();

    }

    useEffect(() => {

        if(vpnAccount.id !== undefined) {

            let accountServers: VpnServer[] = []

            option.servers.forEach((server: VpnServer) => {

                vpnAccountConfiguration.forEach((configuration: VpnAccountConfiguration) => {

                    if(server.id === configuration.server!.id) {

                        accountServers.push(server);

                    }

                });

            });

            changeServers(accountServers);

            changeLimit(vpnAccount.limit);
            changePassword(vpnAccount.password);
            changeStatus(vpnAccount.status.toString());
            changeUsername(vpnAccount.username);

        } else {

            changeLimit(0);
            changePassword('');
            changeStatus('');
            changeUsername('');

        }

    }, [vpnAccount]);

    return (
        <div className="canvas-wrapper">
            <div className="canvas-data">
                <div className="canvas-data-title">
                    <h5><i className="ti ti-world-www mr-5"></i>VPN Account</h5>
                    <button onClick={(event) => close(event)}>
                        <i className="ti ti-x"></i>
                    </button>
                </div>
                <div className="canvas-data-form">
                    <div className="canvas-data-section">
                        <i className="ti ti-creative-commons-nd mr-5"></i>General
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Username" type="text" value={username} onChange={(event) => changeUsername(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Password" type="password" value={password} onChange={(event) => changePassword(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="vpn-account-server">Server</InputLabel>
                            <Select labelId="vpn-account-server" label="Server" value={servers} multiple renderValue={(selected: VpnServer[]) =>
                                (<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                    {
                                        selected.map((value: VpnServer) => (
                                            <Chip key={value.id} label={value.name}/>
                                        ))
                                    }
                                </Box>)} onChange={(event) => serversChange(event.target.value)}>
                                {
                                    option.servers.map((server: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={server}>{server.name}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Speed Limit (Mbps)" type="number" value={limit} onChange={(event) => changeLimit(parseInt(event.target.value))} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="vpn-account-status">Status</InputLabel>
                            <Select labelId="vpn-account-status" label="Status" value={status} onChange={(event) => changeStatus(event.target.value)}>
                                {
                                    Object.keys(Status).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="canvas-data-action">
                    <button onClick={(event) => save(event)}>
                        <i className="ti ti-cloud-upload mr-5"></i>Save
                    </button>
                </div>
            </div>
        </div>
    );

}
