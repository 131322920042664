import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import {Country} from "../../../enums/core/Country";
import {Gender} from "../../../enums/core/Gender";
import {Language} from "../../../enums/core/Language";
import {Status} from "../../../enums/core/Status";
import {UserType} from "../../../enums/user/UserType";
import {Core} from "../../../libraries/Core";
import {Rest} from "../../../libraries/Rest";
import {User} from "../../../models/user/User";
import {UserAddress} from "../../../models/user/UserAddress";
import {UserContact} from "../../../models/user/UserContact";
import {UserName} from "../../../models/user/UserName";
import {UserPassword} from "../../../models/user/UserPassword";
import {UserSaveRequest} from "../../../models/user/UserSaveRequest";


// @ts-ignore
export default function UserDataComponent({user, initializeTable, canvasClose}) {

    const [addressCity, changeAddressCity] = useState<string>('');
    const [addressCountry, changeAddressCountry] = useState<string>('');
    const [addressDistrict, changeAddressDistrict] = useState<string>('');
    const [addressState, changeAddressState] = useState<string>('');
    const [addressStreet, changeAddressStreet] = useState<string>('');
    const [addressZip, changeAddressZip] = useState<string>('');
    const [avatar, changeAvatar] = useState<string>('');
    const [contactDiscord, changeContactDiscord] = useState<string>('');
    const [contactEmail, changeContactEmail] = useState<string>('');
    const [contactPhone, changeContactPhone] = useState<string>('');
    const [contactSkype, changeContactSkype] = useState<string>('');
    const [contactSlack, changeContactSlack] = useState<string>('');
    const [contactTelegram, changeContactTelegram] = useState<string>('');
    const [contactWechat, changeContactWechat] = useState<string>('');
    const [contactWhatsapp, changeContactWhatsapp] = useState<string>('');
    const [gender, changeGender] = useState<string>('');
    const [language, changeLanguage] = useState<string>('');
    const [nameFirst, changeNameFirst] = useState<string>('');
    const [nameLast, changeNameLast] = useState<string>('');
    const [nameMiddle, changeNameMiddle] = useState<string>('');
    const [password, changePassword] = useState<string>('');
    const [passwordConfirm, changePasswordConfirm] = useState<string>('');
    const [status, changeStatus] = useState<string>('');
    const [type, changeType] = useState<string>('');
    const [username, changeUsername] = useState<string>('');

    const inputAvatar: MutableRefObject<any> = useRef(null);

    const avatarClick = (event: any): void => {

        // @ts-ignore
        inputAvatar.current?.click();

        event.preventDefault();

    }

    const avatarUpload = (event: any): void => {

        let formData: FormData = new FormData();

        Array.from(event.target.files).forEach((file: any): void => {

            formData.append('avatars', file);

        });

        fetch(process.env.REACT_APP_API_URL + '/user/upload-avatar/', {
            method: 'POST',
            body: formData
        }).then(response => response.json()).then(data => {

            if(data.result) {

                changeAvatar(data.filenames[0]);

            }

        });

    }

    const close = (event: any) => {

        canvasClose();

        event.preventDefault();

    }

    const save = (event: any): void => {

        fetch(process.env.REACT_APP_API_URL + '/user/save/', Rest.initializeRequest(
            new UserSaveRequest(
                undefined,
                undefined,
                Core.initializeAuthentication(),
                new User(
                    user.id,
                    undefined,
                    undefined,
                    new UserAddress(
                        addressCity,
                        Country[addressCountry as keyof typeof Country],
                        addressDistrict,
                        addressState,
                        addressStreet,
                        addressZip
                    ),
                    avatar,
                    new UserContact(
                        contactDiscord,
                        contactEmail,
                        contactPhone,
                        contactSkype,
                        contactSlack,
                        contactTelegram,
                        contactWechat,
                        contactWhatsapp
                    ),
                    Gender[gender as keyof typeof Gender],
                    Language[language as keyof typeof Language],
                    undefined,
                    new UserName(
                        nameFirst,
                        nameLast,
                        nameMiddle
                    ),
                    new UserPassword(
                        password,
                        password
                    ),
                    Status[status as keyof typeof Status],
                    UserType[type as keyof typeof UserType],
                    username
                )
            ),
            '/user/save/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                initializeTable();

                toast.success(data.response);

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

        event.preventDefault();

    }

    useEffect(() => {

        if(user.id !== undefined) {

            if(user.address.city !== null) {

                changeAddressCity(user.address.city);

            }

            changeAddressCountry(user.address.country);

            if(user.address.district !== null) {

                changeAddressDistrict(user.address.district);

            }

            if(user.address.state !== null) {

                changeAddressState(user.address.state);

            }

            if(user.address.street !== null) {

                changeAddressStreet(user.address.street);

            }

            if(user.address.zip !== null) {

                changeAddressZip(user.address.zip);

            }

            if(user.avatar !== null) {

                changeAvatar(user.avatar);

            }

            if(user.contact.discord !== null) {

                changeContactDiscord(user.contact.discord);

            }

            if(user.contact.email !== null) {

                changeContactEmail(user.contact.email);

            }

            if(user.contact.phone !== null) {

                changeContactPhone(user.contact.phone);

            }

            if(user.contact.skype !== null) {

                changeContactSkype(user.contact.skype);

            }

            if(user.contact.slack !== null) {

                changeContactSlack(user.contact.slack);

            }

            if(user.contact.telegram !== null) {

                changeContactTelegram(user.contact.telegram);

            }

            if(user.contact.wechat !== null) {

                changeContactWechat(user.contact.wechat);

            }

            if(user.contact.whatsapp !== null) {

                changeContactWhatsapp(user.contact.whatsapp);

            }

            changeGender(user.gender);
            changeLanguage(user.language);
            changeNameFirst(user.name.first);

            if(user.name.last !== null) {

                changeNameLast(user.name.last);

            }

            if(user.name.middle !== null) {

                changeNameMiddle(user.name.middle);

            }

            changePassword(user.password.main);
            changePasswordConfirm(user.password.main);
            changeStatus(user.status);
            changeType(user.type);
            changeUsername(user.username);

        } else {

            changeAddressCity('');
            changeAddressCountry('');
            changeAddressDistrict('');
            changeAddressState('');
            changeAddressStreet('');
            changeAddressZip('');
            changeAvatar('');
            changeContactDiscord('');
            changeContactEmail('');
            changeContactPhone('');
            changeContactSkype('');
            changeContactSlack('');
            changeContactTelegram('');
            changeContactWechat('');
            changeContactWhatsapp('');
            changeGender('');
            changeLanguage('');
            changeNameFirst('');
            changeNameLast('');
            changeNameMiddle('');
            changePassword('');
            changePasswordConfirm('');
            changeStatus('');
            changeType('');
            changeUsername('');

        }

    }, [user]);

    return (
        <div className="canvas-wrapper">
            <div className="canvas-data">
                <div className="canvas-data-title">
                    <h5><i className="ti ti-user-shield mr-5"></i>User</h5>
                    <button onClick={(event) => close(event)}>
                        <i className="ti ti-x"></i>
                    </button>
                </div>
                <div className="canvas-data-form">
                    <div className="canvas-data-section">
                        <i className="ti ti-creative-commons-nd mr-5"></i>General
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Username" type="text" value={username} onChange={(event) => changeUsername(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Password" type="password" value={password} onChange={(event) => changePassword(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Confirm Password" type="password" value={passwordConfirm} onChange={(event) => changePasswordConfirm(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="user-type">Type</InputLabel>
                            <Select labelId="user-type" label="Type" value={type} onChange={(event) => changeType(event.target.value)}>
                                {
                                    Object.keys(UserType).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="First Name" type="text" value={nameFirst} onChange={(event) => changeNameFirst(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Middle Name" type="text" value={nameMiddle} onChange={(event) => changeNameMiddle(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Last Name" type="text" value={nameLast} onChange={(event) => changeNameLast(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="user-gender">Gender</InputLabel>
                            <Select labelId="user-gender" label="Gender" value={gender} onChange={(event) => changeGender(event.target.value)}>
                                {
                                    Object.keys(Gender).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="user-language">Language</InputLabel>
                            <Select labelId="user-language" label="Language" value={language} onChange={(event) => changeLanguage(event.target.value)}>
                                {
                                    Object.keys(Language).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="canvas-data-field">
                        <label className="avatar-label">Avatar</label>
                        <div className="avatar-preview">
                            <input ref={inputAvatar} type="file" accept="image/gif, image/jpeg, image/jpg, image/png" hidden onChange={(event) => avatarUpload(event)}/>
                            {
                                (avatar !== null && avatar !== '') &&
                                <span className="avatar-preview-image">
                                    <img src={process.env.REACT_APP_API_URL + avatar} alt={process.env.REACT_APP_APPLICATION_NAME + ' User Avatar'}/>
                                    <i className="ti ti-x"></i>
                                </span>
                            }
                            <button onClick={(event) => avatarClick(event)}>
                                <i className="ti ti-cloud-upload mr-5"></i>Upload
                            </button>
                        </div>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="user-status">Status</InputLabel>
                            <Select labelId="user-status" label="Status" value={status} onChange={(event) => changeStatus(event.target.value)}>
                                {
                                    Object.keys(Status).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="canvas-data-section">
                        <i className="ti ti-map-pins mr-5"></i>Address
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Street" type="text" value={addressStreet} onChange={(event) => changeAddressStreet(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="City" type="text" value={addressCity} onChange={(event) => changeAddressCity(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="District" type="text" value={addressDistrict} onChange={(event) => changeAddressDistrict(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="State" type="text" value={addressState} onChange={(event) => changeAddressState(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="user-country">Country</InputLabel>
                            <Select labelId="user-country" label="Country" value={addressCountry} onChange={(event) => changeAddressCountry(event.target.value)}>
                                {
                                    Object.keys(Country).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="ZIP" type="text" value={addressZip} onChange={(event) => changeAddressZip(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-section">
                        <i className="ti ti-address-book mr-5"></i>Contact
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Email" type="text" value={contactEmail} onChange={(event) => changeContactEmail(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Phone" type="text" value={contactPhone} onChange={(event) => changeContactPhone(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Whatsapp" type="text" value={contactWhatsapp} onChange={(event) => changeContactWhatsapp(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Telegram" type="text" value={contactTelegram} onChange={(event) => changeContactTelegram(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Wechat" type="text" value={contactWechat} onChange={(event) => changeContactWechat(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Discord" type="text" value={contactDiscord} onChange={(event) => changeContactDiscord(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Skype" type="text" value={contactSkype} onChange={(event) => changeContactSkype(event.target.value)} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <TextField label="Slack" type="text" value={contactSlack} onChange={(event) => changeContactSlack(event.target.value)} variant="outlined"/>
                    </div>
                </div>
                <div className="canvas-data-action">
                    <button onClick={(event) => save(event)}>
                        <i className="ti ti-cloud-upload mr-5"></i>Save
                    </button>
                </div>
            </div>
        </div>
    );

}
