import React, {useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import {Status} from "../../../enums/core/Status";
import {TableFilterType} from "../../../enums/core/table/TableFilterType";
import {UserType} from "../../../enums/user/UserType";
import {Core} from "../../../libraries/Core";
import {Rest} from "../../../libraries/Rest";
import {Asset} from "../../../models/asset/Asset";
import {DataRequest} from "../../../models/core/DataRequest";
import {Table} from "../../../models/core/table/Table";
import {TableFilter} from "../../../models/core/table/TableFilter";
import {TableRequest} from "../../../models/core/table/TableRequest";
import {TableSort} from "../../../models/core/table/TableSort";
import TableFilterComponent from "../TableFilterComponent";
import TablePaginationComponent from "../TablePaginationComponent";
import AssetActionComponent from "./AssetActionComponent";
import AssetDataComponent from "./AssetDataComponent";


export default function AssetComponent() {

    // @ts-ignore
    const {account, canvasClose, canvasOpen} = useOutletContext();

    const [filters, changeFilters] = useState<TableFilter[]>([
        new TableFilter('symbol', 'Symbol', [], false, TableFilterType.String, ''),
        new TableFilter('name', 'Name', [], false, TableFilterType.String, ''),
        new TableFilter('status', 'Status', [Status.Active, Status.Inactive], false, TableFilterType.Enum, '')
    ]);
    const [table, changeTable] = useState<Table>(
        Core.initializeTable([new TableSort('symbol', 1)])
    );

    const [asset, changeAsset] = useState<Asset>(Core.initializeAsset());

    const canvas = (event: any, id: string | undefined = undefined) => {

        initializeData(id);
        canvasOpen();

        event.preventDefault();

    }

    const initializeData = (id: string | undefined = undefined) => {

        fetch(process.env.REACT_APP_API_URL + '/asset/data/', Rest.initializeRequest(
            new DataRequest(undefined, undefined, undefined, id),
            '/asset/data/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                if(data.data !== null) {

                    changeAsset(data.data);

                } else {

                    changeAsset(Core.initializeAsset());

                }

            }

        }).catch(error => {

            toast.error(error);

        });

    }

    const initializeTable = () => {

        fetch(process.env.REACT_APP_API_URL + '/asset/table/', Rest.initializeRequest(
            new TableRequest(undefined, undefined, Core.initializeAuthentication(), filters, table.pagination!.limit!.value, table.offset, table.sorts),
            '/asset/table/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                if(data.data !== undefined) {

                    changeTable(Core.renderNewTable(table, data.data, data.total));

                }

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

    }

    useEffect(() => {

        initializeTable();

    }, []);

    return (
        <div className="content-wrapper">
            <div className="table">
                <div className="table-button">
                    <TableFilterComponent filters={filters} changeFilters={changeFilters} initializeTable={initializeTable}></TableFilterComponent>
                    <div className="table-action">
                        <button>
                            <i className="ti ti-cloud-download mr-5"></i>Export
                        </button>
                        {
                            (account.type === UserType.Owner || account.type === UserType.Administrator) &&
                            <button onClick={(event) => canvas(event)}>
                                <i className="ti ti-file-plus mr-5"></i>Add New
                            </button>
                        }
                    </div>
                </div>
                <div className="table-data">
                    <table className="table-full">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Symbol</th>
                            <th>Name</th>
                            <th>Market</th>
                            <th>Exchange</th>
                            <th>Market Cap</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            (table.rows.length === 0) && <tr>
                                <td className="text-center" colSpan={7}>No Data Available</td>
                            </tr>
                        }
                        {
                            (table.rows.length > 0) && table.rows.map((row: Asset, index: number) => {

                                return (
                                    <tr key={index}>
                                        <td>{(table.offset! + (index + 1))}</td>
                                        <td>{row.symbol}</td>
                                        <td>{row.name}</td>
                                        <td>{Core.toStandardCase(row.market!.type!)}</td>
                                        <td>{Core.toStandardCase(row.exchange!)}</td>
                                        <td>{Core.toNumberSeparator(row.market!.cap!)}</td>
                                        <AssetActionComponent account={account} row={row} initializeTable={initializeTable}></AssetActionComponent>
                                    </tr>
                                );

                            })
                        }
                        </tbody>
                    </table>
                </div>
                <TablePaginationComponent table={table} tableIndex={0} changeTable={changeTable} initializeTable={initializeTable}></TablePaginationComponent>
            </div>
            <AssetDataComponent asset={asset} initializeTable={initializeTable} canvasClose={canvasClose}></AssetDataComponent>
        </div>
    );

}
